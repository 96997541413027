<template>
  <div class="productList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>店长列表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="search_box">
      <span>真实姓名：</span>
      <el-input
        style="width: 200px"
        v-model="formData.trueName"
        placeholder="请输入真实姓名"
      />
      <span>手机号：</span>
      <el-input
        style="width: 200px"
        v-model.trim="formData.phone"
        placeholder="请输入手机号"
      />

      <span>会员等级：</span>
      <el-select v-model="formData.levelId" placeholder="请选择">
        <el-option
          v-for="item in allLevelList"
          :key="item.cateId"
          :label="item.cateName"
          :value="item.cateId"
        >
        </el-option>
      </el-select>
      <br />
      <br />
      <br />

      <el-button type="primary" class="btn" @click="getTableList()">查询</el-button>
      <el-button type="primary" class="btn" @click="createForm()">生成报表</el-button>
      <el-button
        type="primary"
        class="btn"
        @click="goBack()"
        v-if="formData.fatherId != 0"
        >返回上级</el-button
      >
    </div>
    <br />
    <span>申请手机号：</span>
    <el-input
      style="width: 200px; margin-right: 20px"
      v-model.trim="formData.applyPhone"
      placeholder="请输入申请手机号"
    />
    <span>注册时间：</span>
    <el-date-picker
      @change="onChange"
      v-model="addTime"
      type="datetimerange"
      align="right"
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd HH:mm:ss"
      :picker-options="pickerOptions"
    >
    </el-date-picker>
    <el-table
      border
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        align="center"
        :reserve-selection="true"
        width="55"
      >
      </el-table-column>
      <el-table-column prop="userId" label="用户ID"> </el-table-column>
      <el-table-column prop="trueName" label="真实名称"> </el-table-column>
      <el-table-column prop="shopName" label="店长名称" width="120"> </el-table-column>
      <el-table-column prop="label" label="店长审核备注内容"> </el-table-column>
      <el-table-column prop="" label="当前头衔">
        <template slot-scope="{ row }">
          <el-tooltip
            class="item"
            effect="dark"
            content="点击修改等级"
            :enterable="false"
            placement="top"
          >
            <el-select
              @change="onSelectChange(row)"
              v-model="row.levelId"
              placeholder="请选择"
            >
              <el-option
                v-for="item in allLevelList"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              >
              </el-option>
            </el-select>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column prop="applyPhone" label="申请手机号"> </el-table-column>
      <el-table-column prop="addTime" label="会员注册时间" width="120"> </el-table-column>
      <el-table-column prop="handleTime" label="店长注册时间" width="120">
      </el-table-column>
      <el-table-column prop="totalProfit" label="总收益"> </el-table-column>
      <el-table-column prop="balance" label="余额"> </el-table-column>
      <el-table-column prop="waitProfit" label="待收益"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            style="margin-left: 0.625rem"
            @click="$router.push(`/shopUserList?fatherId=${scope.row.userId}`)"
          >
            查看详情
          </el-button>
          <br />
          <br />
          <el-button
            type="primary"
            size="mini"
            style="margin-left: 0.625rem"
            @click="isShowDialgo(scope.row.userId, scope.row.label)"
          >
            标签
          </el-button>
          <el-button
            type="primary"
            size="mini"
            style="margin-left: 0.625rem"
            @click="toShopJourn(scope.row.userId)"
          >
            查看日志
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block_box">
      <div>
        <el-button type="primary" @click="allSetLabel()"> 批量修改标签 </el-button>
        <el-button type="primary" @click="allSetLevel()"> 批量修改等级 </el-button>
        <el-button type="primary">
          <excel-import :on-success="setExlex">
            <div class="daoru">导入表格</div>
          </excel-import>
        </el-button>
      </div>
      <div style="display:flex;align-items: flex-end;">
        <!-- 佣金总金额
裂变店长人数
直推佣金总金额
获得直推佣金人数
邀请佣金总金额
 获得邀请佣金人数 -->
       
       
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="formData.currentPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="formData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formData.total"
        >
        </el-pagination>
      
       
      </div>

    </div>
    <div>
        <span style="margin:0 8px 5px 0">佣金总金额：{{ totalPrice.split("_")[0] }}</span>
        <span style="margin:0 8px 5px 0">裂变店长人数：{{ totalPrice.split("_")[1] }}</span>
        <span style="margin:0 8px 5px 0">直推佣金总金额：{{ totalPrice.split("_")[2] }}</span>
        <span style="margin:0 8px 5px 0">获得直推佣金人数：{{ totalPrice.split("_")[3] }}</span>
        <span style="margin:0 8px 5px 0">邀请佣金总金额：{{ totalPrice.split("_")[4] }}</span>
        <span style="margin:0 8px 5px 0">获得邀请佣金人数：{{ totalPrice.split("_")[5] }}</span>
        </div>

    <!-- 弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="备注"
      :visible.sync="isShowLabel"
      width="50%"
    >
      <el-form ref="form" label-width="100px" class="grade_list">
        <el-form-item label="备注">
          <el-input type="textarea" :rows="4" v-model="remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hiddenLabel">取 消</el-button>
        <el-button type="primary" @click="changeLabel(userId)">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisibleLevel" width="30%">
      <!-- select
       -->
      <el-form label-width="100px" class="grade_list">
        <el-form-item label="等级">
          <el-select v-model="allSetLevelId" placeholder="请选择">
            <el-option
              v-for="item in allLevelList"
              :key="item.cateId"
              :label="item.cateName"
              :value="item.cateId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleLevel = false">取 消</el-button>
        <el-button type="primary" @click="submitAllLevel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import productBox from "./productBox";
import { ExcelImport } from "pikaz-excel-js";
import { materialDelete, changeSort } from "../../api/shopMaterial.js";
import {
  shopownerList2,
  shopownerApply,
  modifyVipLevel, //修改等级
  allDistributionLevel, //所有分销等级
  shopOwnerList, //店长列表
  changeOwnerLabel, //修改店长标签
  downLoadList, //下载报表
  setLabel,
  allSetLv,
  addExlex, //导入表格
} from "../../api/wxShopowner.js";

export default {
  name: "shopUserList",
  components: {
    productBox,
    ExcelImport,
  },
  data() {
    return {
      dialogVisibleLevel: false,
      allSetLevelId: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      options: [],
      formData: {
        phone: "",
        trueName: "",
        fatherId: 0,
        total: 0,
        pageSize: 10,
        currentPage: 1,
        levelId: null,
        beginTime: "", //注册时间开始
        endTime: "", //注册时间结束
        userId: "",
        applyPhone: "",
      },
      downLoadParmas: {
        phone: "",
        trueName: "",
        fatherId: 0,
        // total: 0,
        // pageSize: 10,
        // currentPage: 1,
        levelId: null,
        beginTime: "", //注册时间开始
        endTime: "", //注册时间结束
        userId: "",
      },
      editForm: {
        status: "",
        remark: "",
      },
      tableData: [],
      multipleSelection: "",
      dialogVisible: false,
      dialogVisible2: false,
      allLevelList: [],
      addTime: [],
      isShowLabel: false,
      remark: "",
      label: "",
      userId: "",
      totalPrice: 0,
    };
  },
  watch: {
    $route(to, before) {
      // console.log('to', to)
      // console.log('before', before)
      if (to.path == "/shopUserList") {
        this.formData.fatherId = to.query.fatherId || 0;
        this.getTableList(); // 获取表格数据
      }
    },
  },
  created() {},
  mounted() {
    // console.log(this.$route)
    this.getTableList(); // 获取表格数据
    this.getAllDistributionLevel();
    // this.getShopOwnerList()
  },
  methods: {
    // 上传表格
    async setExlex(e, file) {
      let form = new FormData();
      form.append("file", file);
      let { data } = await addExlex(form);
      if (data.code == 0) {
        this.getTableList(); // 获取表格数据
        this.$message({
          message: data.msg,
          type: "success",
          duration: 0,
          showClose: true,
        });
      } else {
        this.$message({
          message: data.msg,
          type: "warning",
          duration: 0,
          showClose: true,
        });
      }
    },
    allSetLabel() {
      if (!this.multipleSelection) {
        return this.$message.warning("请选择");
      }
      this.$prompt("请输入标签", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",

        inputErrorMessage: "请输入内容",
        inputValidator: (e) => {
          console.log(e);
          if (!e) {
            return false;
          }
        },
      })
        .then(async ({ value }) => {
          const params = {
            userIds: this.multipleSelection,
            label: value,
          };
          const { data } = await setLabel(params);
          if (data.code == 0) {
            this.$message.success(data.msg);
            this.getTableList(); // 获取表格数据
          } else {
            this.$message.warning(data.msg);
          }
        })
        .catch(() => {});
    },
    allSetLevel() {
      if (!this.multipleSelection) {
        return this.$message.warning("请选择");
      }
      this.dialogVisibleLevel = true;
    },
    async submitAllLevel() {
      if (!this.allSetLevelId) {
        return this.$message.warning("请选择等级");
      }
      if (this.load) return;
      this.load = true;
      const params = {
        userIds: this.multipleSelection,
        levelId: this.allSetLevelId,
      };
      const { data } = await allSetLv(params);
    
      this.load = false;
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.dialogVisibleLevel = false;
        this.getTableList(); // 获取表格数据
      } else {
        this.$message.warning(data.msg);
      }
    },
    rowKey(row) {
      return row.userId;
    },
    isShowDialgo(e, e1) {
      this.isShowLabel = true;
      this.userId = e;
      this.remark = e1;
    },
    hiddenLabel() {
      this.isShowLabel = false;
    },
    onChange(value) {
      console.log(value);
      if (value) {
        this.formData.beginTime = value[0];
        this.formData.endTime = value[1];
      } else {
        this.formData.beginTime = null;
        this.formData.endTime = null;
      }
    },
    async getAllDistributionLevel() {
      const { data } = await allDistributionLevel();
      this.allLevelList = data.data;
      console.log(data);
    },
    onSelectChange(row) {
      this.$confirm("是否更改等级?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await modifyVipLevel({
            userId: row.userId,
            levelId: row.levelId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getTableList();
          } else {
            this.$message({
              type: "warning",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.getTableList();
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    goBack() {
      if (this.formData.fatherId > 0) {
        this.$router.go(-1);
      }
    },
    async getTableList() {
      const { data } = await shopownerList2(this.formData);
      // this.formData.levelId = data.list.map(item=>{
      //   return item.levelId
      // })
      this.tableData = data.list;
      // console.log(this.tableData,'kasjdhkajshdkajsaskldhaskjdhaksjdh')
      this.formData.total = data.pagination.total;
      this.formData.pageSize = data.pagination.pageSize;
      this.formData.currentPage = data.pagination.current;
      this.totalPrice = data.msg;
    },
    toShopJourn(id) {
      this.$router.push("/shopJournal?id=" + id);
    },
    //下载报表
    async createForm() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let date = +new Date();
      const { data } = await downLoadList({
        phone: this.formData.phone,
        trueName: this.formData.trueName,
        fatherId: this.formData.fatherId,
        levelId: this.formData.levelId,
        userId: this.formData.userId,
        beginTime: this.formData.beginTime || "",
        endTime: this.formData.endTime || "",
        applyPhone: this.formData.applyPhone || "",
      });
      let url = window.URL.createObjectURL(data); //转换文件流为URL
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", `万旅网小程序V2.0店长列表报表_${date}.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      loading.close();
    },
    //修改备注标签
    async changeLabel(userId) {
      const { data } = await changeOwnerLabel({
        userId: userId,
        label: this.remark,
      });
      this.$message({
        message: "设置成功",
        type: "success",
      });
      this.isShowLabel = false;
      this.getTableList();
    },
    async getShopOwnerList() {
      const { data } = await shopOwnerList(this.tableData);
    },
    //修改会员等级
    // async changeVipLevel(){
    //   const { data } = await modifyVipLevel({
    //     userId:this.vipId,
    //     levelId:this.vipLevel,
    //   });
    //   console.log(data)
    // },
    async toModify(status, list) {
      if (list.length < 1) {
        return this.$message.error("请选择需要操作的商品！");
      }
      let ids = list
        .map((item) => {
          return item.materialId;
        })
        .join(",");
      const { data } = await materialDelete({ ids });
      if (data.code == 0) {
        this.getTableList();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    showApply(row) {
      this.editForm.applyId = row.applyId;
      this.editForm.phone = row.phone;
      this.editForm.idCard = row.idCard;
      this.editForm.addTime = row.addTime;
      this.editForm.trueName = row.trueName;
      this.editForm.referrerInfo = row.referrerInfo;
      this.dialogVisible = true;
    },
    async toApply(status) {
      let setData = {
        status,
      };
      if (status == 2 && this.editForm.remark == "") {
        return this.$message.error("请填写审核不通过原因");
      }
      setData.remark = this.editForm.remark;
      setData.applyId = this.editForm.applyId;

      const { data } = await shopownerApply(setData);
      console.log("编辑回调", data);
      if (data.code == 0) {
        this.hideVisible();
        this.getTableList();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    // 开始修改排序
    onChangeSort(row) {
      row.inputVisible = true;
      this.tableData = [...this.tableData];
    },
    // 修改排序
    async handleInputConfirm(row) {
      const { data } = await changeSort({
        materialId: row.materialId,
        dept: Number(row.dept),
      });
      if (data.code !== 0) {
        this.$message({
          message: "设置失败",
          type: "error",
        });
      } else {
        this.$message({
          message: "设置成功",
          type: "success",
        });
      }
      row.inputVisible = false;
    },
    // 隐藏弹窗
    hideVisible() {
      this.dialogVisible = false;
      this.dialogVisible2 = false;
    },
    handleSelectionChange(arr) {
      this.multipleSelection = arr.map((item) => item.userId).join(",");
      console.log(this.multipleSelection);
    },
    handleSizeChange(num) {
      console.log(num);
      this.formData.pageSize = num;
      this.getTableList();
    },
    handleCurrentChange(num) {
      console.log("currentPage", num);
      this.formData.currentPage = num;
      this.getTableList();
    },
    onInput(value) {
      var reg = /^(0\.?\d{0,5}|[1-9]\d*\.?\d{0,5})$/;
      if (!reg.test(value)) {
        return this.$message({
          type: "error",
          message: "请输入正确的数字!",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.block_box {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.productList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .add {
    margin-top: 20px;
    
  }
  .el-table {
    margin-top: 50px;
  }
  .search_box {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .btn {
      margin: 0 20px;
    }
    span {
      display: inline-block;
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  /deep/.el-dialog__header {
    border-bottom: 1px solid #f1f1f1;
  }
  /deep/.el-dialog__footer {
    border-top: 1px solid #f1f1f1;
  }
  .btn_box {
    margin-top: 24px;
  }
  .modify_btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  /deep/.popconfirm {
    margin-right: 10px;
  }
}
</style>
